import { AppBar, Box, Container, Toolbar, Typography, Divider, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useState } from 'react';
import CustomButton from '../components/CustomButton';
import Services from '../services/Services';

export default function DeliveryDetailsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const item = location.state;
    const localToken = localStorage.getItem('userToken');

    const [deliveryCode, setDeliveryCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [resultModalOpen, setResultModalOpen] = useState(false);
    const [resultModalMessage, setResultModalMessage] = useState("");
    const [resultModalHeader, setResultModalHeader] = useState("");

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmResendOTP = () => {
        setLoading(true);
        setIsModalOpen(false);
        Services.ResendOTP(localToken, item[0].orderID)
            .then((res) => {
                if (res.data.status) {
                    setLoading(false);
                } else {
                    console.log("err");
                }
            })
            .catch((err) => console.log(err));
    };

    const handleSetOnCourier = () => {
        setLoading(true)
        Services.SetPostOnCourier(localToken, item[0].orderID)
            .then((res) => {
                if (res.data.status) {
                    setLoading(false)
                    handleBackClick()
                }
                else {
                    console.log("err");
                }
            })
            .catch((err) => console.log(err));
    }

    const handleDeliver = () => {
        setLoading(true);
        const promises = [];
        let passwordErrorOccurred = false;

        for (let i = 0; i < item.length; i++) {
            promises.push(
                Services.DeliverPost(localToken, item[i].postID, deliveryCode)
                    .then((res) => {
                        if (!res.data.status && res.data.message === "Password not match") {
                            passwordErrorOccurred = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        throw err;
                    })
            );
        }

        Promise.all(promises)
            .then(() => {
                setLoading(false);
                if (passwordErrorOccurred) {
                    setResultModalMessage("Girdiğiniz kod hatalı, lütfen kodunuzu kontrol ediniz.");
                    setResultModalHeader("Hata")
                } else {
                    setResultModalMessage("İşlem başarıyla tamamlandı.");
                    setResultModalHeader("Başarılı")
                }
                setResultModalOpen(true);
            })
            .catch((err) => {
                setLoading(false);
                if (!passwordErrorOccurred) {
                    setResultModalMessage("Bir hata oluştu");
                    setResultModalOpen(true);
                }
            });
    };

    const handleCloseResultModal = () => {
        setResultModalOpen(false);
        if (resultModalHeader === "Başarılı") {
            handleBackClick();
        }
    };

    const getStatusTitle = (status) => {
        switch (status) {
            case '9':
                return 'Zimmetimdeki Paketler';
            case '8':
                return 'Zimmet İçin Bekleyen Paketler';
            case '1':
                return 'Teslim Edilen Paketler';
            default:
                return '';
        }
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'left' }}>
            <AppBar position="static" color="transparent" elevation={0} sx={{ alignItems: 'center' }}>
                <Toolbar sx={{ position: 'relative', width: '100%' }}>
                    <Typography
                        sx={{ position: 'absolute', left: 26, cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={handleBackClick}
                    >
                        <ArrowBackIosIcon fontSize='24px' fontWeight='400' />
                    </Typography>
                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>{item[0].orderNumber.split("-")[0]}-{item[0].orderNumber.split("-")[1]}-{item[0].orderNumber.split("-")[2]}</Typography>
                    </Box>
                </Toolbar>
            </AppBar>

            <Typography style={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Inter', textAlign: 'center' }}>
                Adrese Teslimat Servisi
            </Typography>

            <Typography sx={{ fontWeight: '600', fontFamily: 'Inter', fontSize: '14px', marginTop: '26.5px' }}>Teslimat Bilgisi</Typography>
            <Divider sx={{ marginY: 2 }} />

            <Box>

                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Teslimat Durumu: </Typography>
                    <Typography style={{ color: '#B7CDC1', fontWeight: '400', fontSize: '14px' }}>{getStatusTitle(item[0].status)}</Typography>
                </div>

                <Divider sx={{ marginY: 2 }} />
                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Paket Bilgileri</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter' }}>Paket No: {item[0].orderNumber.split("-")[0]}-{item[0].orderNumber.split("-")[1]}-{item[0].orderNumber.split("-")[2]}</Typography>
                </div>

                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Paket Adedi:</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter' }}>{item.length}</Typography>
                </div>

                <Divider sx={{ marginY: 2 }} />

                {/* <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Son Teslim Tarihi:</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter', flexDirection: 'row', display: 'flex', gap: '5px' }}><span style={{ fontSize: '10px', color: '#FF6E60', fontWeight: '700', alignItems: 'center', display: 'flex', gap: '2px' }}><AccessTimeIcon fontSize='10px' /> Son 3 Saat</span> 22.12.2021</Typography>
                </div>

                <Divider sx={{ marginY: 2 }} /> */}

                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Alıcı Bilgileri</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter' }}>{item[0].name} {item[0].lastname}</Typography>
                </div>

                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Telefon</Typography>
                    <Typography style={{ fontWeight: '400', fontSize: '12px', fontFamily: 'Inter' }}>{item[0].phone}</Typography>
                </div>

                <Divider sx={{ marginY: 2 }} />

                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Adrese Teslim</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter' }}>Adres Bilgisi</Typography>
                </div>

                {/* <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>İl / İlçe</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter' }}>İl / İlçe</Typography>
                </div>


                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Mahalle</Typography>
                    <Typography style={{ fontWeight: '600', fontSize: '14px', fontFamily: 'Inter' }}>Mahalle Adı</Typography>
                </div> */}


                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '5px' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Açık Adres</Typography>
                    <Typography style={{ fontWeight: '400', fontSize: '12px', fontFamily: 'Inter', maxWidth: '70%', textAlign: 'right' }}>{item[0].deliveryTypeInfo.text}</Typography>
                </div>

                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '5px' }}>
                    <Typography style={{ color: '#7D8290', fontWeight: '600', fontFamily: 'Inter', fontSize: '12px' }}>Adres Notu</Typography>
                    <Typography style={{ fontWeight: '400', fontSize: '12px', fontFamily: 'Inter', maxWidth: '80%', textAlign: 'right' }}>{item[0].deliveryTypeInfo.additionalNote}</Typography>
                </div>

            </Box>

            <Divider sx={{ marginY: 2 }} />

            {item[0].status === "9" &&
                <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
                    <TextField
                        label="Teslim Kodu"
                        variant="outlined"
                        fullWidth
                        style={{ backgroundColor: 'white' }}
                        value={deliveryCode}
                        onChange={(e) => setDeliveryCode(e.target.value)}
                    />
                    <CustomButton onClick={handleDeliver} disabled={!deliveryCode || loading} fullWidth sx={{ backgroundColor: '#E92434', marginTop: '17px' }}>Teslim Et</CustomButton>
                    <Typography onClick={handleOpenModal} sx={{ color: '#DA3831', marginTop: '20px', cursor: 'pointer', fontFamily: 'Inter', fontSize: '14px', textDecoration: 'underline' }}>Yeniden Teslim Kodu Gönder</Typography>
                </Box>
            }

            {item[0].status === "8" &&
                <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
                    <CustomButton disabled={loading} onClick={handleSetOnCourier} fullWidth sx={{ backgroundColor: '#E92434', marginTop: '17px' }}>Zimmetime Al</CustomButton>
                </Box>
            }

            <Dialog open={isModalOpen} onClose={handleCloseModal}>
                <DialogTitle>Yeniden Teslim Kodu Gönder</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Yeni teslim kodu göndermek istediğinize emin misiniz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">Hayır</Button>
                    <Button onClick={handleConfirmResendOTP} color="primary" autoFocus>Evet</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={resultModalOpen} onClose={handleCloseResultModal}>
                <DialogTitle>{resultModalHeader}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{resultModalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseResultModal} color="primary">
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
}
