import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const CustomButton = styled(Button)({
  backgroundColor: '#FF6E60', 
  color: '#fff', 
  padding: '8px 16px',
  textTransform: 'none', 
  borderRadius: '8px',
  height:'42px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#bd5248',
  },
  '&:disabled': {
    backgroundColor: '#b0bec5',
    color: '#eceff1',
  },
});

export default CustomButton;
