import { Container, AppBar, Toolbar, Typography, TextField, Box } from '@mui/material';
import Logo from '../assets/Logo.png';
import TR from '../assets/TR.png'
import CustomButton from '../components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Services from '../services/Services';

const StyledTextField = styled(TextField)({
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
    },
    '& input[type=number]': {
        '-moz-appearance': 'textfield',
    },
});

export default function Login() {
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const handleNextClick = () => {
        setLoading(true)
        const OTPCode = randomIntFromInterval(100000, 999999)
        const message = "Handsfree Kurye giriş OTP Kodunuz: " + OTPCode
        Services.SendSMS(token, message, phone)
            .then((res) => {
                if (res.data.status) {
                    setLoading(false)
                    navigate('/check-otp', { state: { OTPCode, phone } });
                }
                else {
                    console.log("err");
                    setLoading(false)
                }
            })
            .catch((err) => setLoading(false));

    };

    const getToken = () => {
        Services.GetToken()
            .then((res) => {
                if (res.data.status) {
                    setToken(res.data.result.token);
                    localStorage.setItem('token', res.data.result.token);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getToken()
    }, []);

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
            <AppBar position="static" color="transparent" elevation={0} sx={{ alignItems: 'center' }}>
                <Toolbar sx={{ position: 'relative', width: '100%' }}>
                    <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        <img src={Logo} alt="Logo" style={{ height: '24.45px' }} />
                    </Box>

                    <Box sx={{ marginLeft: 'auto' }}>
                        <img src={TR} alt="TR Flag" />
                    </Box>
                </Toolbar>
            </AppBar>
            <Typography style={{ fontWeight: '700', fontSize: '16', fontFamily: 'Inter', marginTop: '49.62px' }}>
                Adrese Teslimat Servisi
            </Typography>

            <StyledTextField
                label="Telefon"
                variant="outlined"
                fullWidth
                value={phone}
                style={{ marginTop: '2rem', backgroundColor: 'white' }}
                type='number'
                onChange={(e) => setPhone(e.target.value)}
                onInput={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue < 0) {
                        inputValue = '0';
                    }
                    if (inputValue.startsWith('0')) {
                        inputValue = inputValue.slice(1);
                    }
                    if (inputValue.length > 10) {
                        inputValue = inputValue.slice(0, 10);
                    }
                    if (inputValue !== e.target.value) {
                        e.target.value = inputValue;
                        setPhone(inputValue);
                    }
                }}
            />
            <CustomButton disabled={phone.length !== 10 || loading} onClick={handleNextClick} fullWidth sx={{ marginTop: '42px' }}>
                Devam Et
            </CustomButton>
        </Container>
    );
}
