import { AppBar, Avatar, Box, Container, Toolbar, Typography, Select, MenuItem, Card, Grid, InputBase } from '@mui/material';
import { styled } from '@mui/system';
import Logo from '../assets/Logo.png';
import TR from '../assets/TR.png';
import locationIcon from '../assets/locationIcon.png';
import cargoIcon from '../assets/cargoIcon.png';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Services from '../services/Services';

// Custom Select styles
const CustomSelect = styled(Select)(({ theme }) => ({
    backgroundColor: '#F6F6F6',
    borderRadius: '12px',
    fontSize: '12px',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E0E0E0',
    '& .MuiSelect-icon': {
        display: 'none', // Default arrow iconu gizliyoruz
    }
}));

// Custom icon + text styling
const LocationWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#6D6D6D',
}));

export default function ListPage() {
    const navigate = useNavigate();
    const mallID = localStorage.getItem('mallID');
    const userID = localStorage.getItem('userID');
    const name = localStorage.getItem('name');
    const lastname = localStorage.getItem('lastname');

    const [selectedLocation, setSelectedLocation] = useState('Legend Antalya');
    const [packages, setPackages] = useState([]);
    const [onCourierPackages, setOnCourierPackages] = useState([]);
    const [otherPackages, setOtherPackages] = useState([]);

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    useEffect(() => {
        const localToken = localStorage.getItem('userToken');

        let byStatusArr = []
        let byStatusOnMeArr = []

        Services.GetPostsByStatus(localToken, mallID)
            .then((res) => {
                if (res.data.status) {
                    setOtherPackages(res.data.result);
                }
                else {
                    console.log("err");
                }
            })
            .catch((err) => console.log(err));

        Services.GetPostsByStatusOnMe(localToken, mallID, userID)
            .then((res) => {
                if (res.data.status) {
                    setOnCourierPackages(res.data.result);
                }
                else {
                    console.log("err");
                }
            })
            .catch((err) => console.log(err));

        const combinedArray = byStatusArr.concat(byStatusOnMeArr);

        setPackages(groupByOrderID(combinedArray))

    }, []);

    useEffect(() => {
        const combinedArray = otherPackages.concat(onCourierPackages);
        setPackages(groupByOrderID(combinedArray))
    }, [onCourierPackages, otherPackages]);


    const getStatusTitle = (status) => {
        switch (status) {
            case '9':
                return 'Zimmetimdeki Paketler';
            case '8':
                return 'Zimmet İçin Bekleyen Paketler';
            case '1':
                return 'Teslim Edilen Paketler';
            default:
                return '';
        }
    };

    const groupPackagesByStatus = (status) => {
        return packages.filter(pkg => pkg[0].status === status);
    };

    const handleCardClick = (pkg) => {
        navigate(`/package`, { state: pkg });
    };

    function groupByOrderID(data) {
        const groupedOrders = [];
        const orderMap = new Map();

        data.forEach(item => {
            const key = item.orderID;
            if (!orderMap.has(key)) {
                // Eğer grup yoksa yeni bir grup oluştur
                orderMap.set(key, []);
            }
            orderMap.get(key).push(item);
        });

        // Tüm grupları diziye ekle
        orderMap.forEach(group => groupedOrders.push(group));

        return groupedOrders;
    }
    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
            <AppBar position="sticky" color="transparent" elevation={0} sx={{ alignItems: 'center', backgroundColor: "#F5F5F5" }}>
                <Toolbar sx={{ position: 'relative', width: '100%' }}>
                    <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        <img src={Logo} alt="Logo" style={{ height: '24.45px' }} />
                    </Box>
                    <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                        <img src={TR} alt="TR Flag" />
                        <Avatar sx={{ marginLeft: 1, height: '30px', width: '30px', fontSize: '14px', backgroundColor: '#FF6E60' }}>{name[0]?.toUpperCase() + lastname[0]?.toUpperCase()}</Avatar>
                    </Box>
                </Toolbar>
            </AppBar>
            <Typography style={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Inter', marginTop: '49.62px' }}>
                Adrese Teslimat Servisi
            </Typography>

            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Typography sx={{ fontWeight: '700', marginTop: '20px', marginBottom: '10px', textAlign: 'left', fontSize: '12px' }}>{getStatusTitle('9')}</Typography>

                {/* <CustomSelect
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    displayEmpty
                    sx={{ backgroundColor: '#F0F0F0', borderRadius: '8px', height: '36px', marginBottom: '10px' }}
                    input={<InputBase />}
                    renderValue={() => (
                        <LocationWrapper>
                            <img src={locationIcon} alt="l" />
                            <Typography sx={{ fontSize: '12px', color: '#757575', fontFamily: 'Inter', fontWeight: '600' }}>{selectedLocation}</Typography>
                        </LocationWrapper>
                    )}
                >
                    <MenuItem value="Legend Antalya">Legend Antalya</MenuItem>
                    <MenuItem value="Lokasyon 2">Lokasyon 2</MenuItem>
                    <MenuItem value="Lokasyon 3">Lokasyon 3</MenuItem>
                </CustomSelect> */}
            </Box>

            {/* Zimmetimdeki Paketler */}
            <Grid container spacing={2}>
                {groupPackagesByStatus('9').map((pkg) => (
                    <Grid item xs={12} key={pkg.id}>
                        <Card sx={{ backgroundColor: "#D0FCE2", height: '53px', padding: '10px', cursor: 'pointer' }} onClick={() => handleCardClick(pkg)}>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <img style={{ height: '25px' }} src={cargoIcon} alt="cargoIcon" />
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '15px' }}>
                                            <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>{pkg[0].orderNumber.split("-")[0]}-{pkg[0].orderNumber.split("-")[1]}-{pkg[0].orderNumber.split("-")[2]}</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#7D8290' }}>Paket Adedi: {pkg.length}</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#015876', fontWeight: '600', fontFamily: 'Inter' }}>Teslim Adresi: {pkg[0].deliveryTypeInfo.text}</Typography>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ height: '100%', alignItems: 'flex-end', display: 'flex' }}>
                                    <Typography sx={{ fontSize: '12px', color: '#555' }}>{pkg.brand}</Typography>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Zimmet İçin Bekleyen Paketler */}
            <Typography sx={{ fontWeight: '700', marginTop: '20px', marginBottom: '10px', textAlign: 'left', fontSize: '12px' }}>{getStatusTitle('8')}</Typography>
            <Grid container spacing={2}>
                {groupPackagesByStatus('8').map((pkg) => (
                    <Grid item xs={12} key={pkg.id}>
                        <Card sx={{ backgroundColor: "#FDF6D0", height: '53px', padding: '10px', cursor: 'pointer' }} onClick={() => handleCardClick(pkg)}>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <img style={{ height: '25px' }} src={cargoIcon} alt="cargoIcon" />
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '15px' }}>
                                            <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>{pkg[0].orderNumber.split("-")[0]}-{pkg[0].orderNumber.split("-")[1]}-{pkg[0].orderNumber.split("-")[2]}</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#7D8290' }}>Paket Adedi: {pkg.length}</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#015876', fontWeight: '600', fontFamily: 'Inter' }}>Teslim Adresi: {pkg[0].deliveryTypeInfo.text}</Typography>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ height: '100%', alignItems: 'flex-end', display: 'flex' }}>
                                    <Typography sx={{ fontSize: '12px', color: '#555' }}>{pkg.brand}</Typography>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Teslim Edilen Paketler */}
            <Typography sx={{ fontWeight: '700', marginTop: '20px', marginBottom: '10px', textAlign: 'left', fontSize: '12px' }}>{getStatusTitle('1')}</Typography>
            <Grid container spacing={2}>
                {groupPackagesByStatus('1').map((pkg) => (
                    <Grid item xs={12} key={pkg.id}>
                        <Card sx={{ backgroundColor: "#E0E0E0", height: '53px', padding: '10px', cursor: 'pointer' }} onClick={() => handleCardClick(pkg)}>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <img style={{ height: '25px' }} src={cargoIcon} alt="cargoIcon" />
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '15px' }}>
                                            <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>{pkg[0].orderNumber.split("-")[0]}-{pkg[0].orderNumber.split("-")[1]}-{pkg[0].orderNumber.split("-")[2]}</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#7D8290' }}>Paket Adedi: {pkg.length}</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#015876', fontWeight: '600', fontFamily: 'Inter' }}>Teslim Adresi: {pkg[0].deliveryTypeInfo.text}</Typography>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ height: '100%', alignItems: 'flex-end', display: 'flex' }}>
                                    <Typography sx={{ fontSize: '12px', color: '#555' }}>{pkg.brand}</Typography>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
