import React from 'react';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/system';

const CustomCheckBox = styled((props) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
    checkedIcon={<CheckBoxIcon fontSize="large" style={{ color: '#ff6347' }} />} // Custom color for checked state
    {...props}
  />
))(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 99, 71, 0.1)', // Light hover effect
  },
}));

export default CustomCheckBox;
