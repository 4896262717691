import axios from "axios";
import {
  BASE_URL,
  KEY,
  SECRET
} from ".";

export default {

  GetToken() {
    return axios({
      method: "post",
      url: `${BASE_URL}/get-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        key: KEY,
        secret: SECRET,
      },
    });
  },

  LoginByPhone(token, phone) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/user-login-by-phone`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        phone: phone
      },
    });
  },

  SendSMS(token, message, phone) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/send-sms`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        message: message,
        phone: phone,
        type: "malllogistics",
      },
    });
  },

  GetPostsByStatus(token, mallID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/get-posts-by-status`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        mallID: mallID,
        status: [1, 8]
      },
    });
  },

  GetPostsByStatusOnMe(token, mallID, userID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/get-posts-by-status`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        courierID: userID,
        mallID: mallID,
        status: [9]
      },
    });
  },

  SetPostOnCourier(token, orderID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-set-on-courier`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderID: orderID
      },
    });
  },

  DeliverPost(token, postID, deliveryPassword) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-set-completed`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        postID: postID,
        deliveryPassword: deliveryPassword
      },
    });
  },

  ResendOTP(token, orderID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/re-send-otp`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        orderID: orderID
      },
    });
  },
};
