import { Container, AppBar, Toolbar, Typography, TextField, FormControlLabel, Box } from '@mui/material';
import Logo from '../assets/Logo.png';
import TR from '../assets/TR.png'
import CustomButton from '../components/CustomButton';
import { useState } from 'react';
import CustomCheckBox from '../components/CustomCheckBox';
import { useLocation, useNavigate } from 'react-router-dom';
import Services from '../services/Services';

export default function CheckOTP() {
    const navigate = useNavigate();
    const location = useLocation();
    const item = location.state;

    const [otpCode, setOtpCode] = useState("");
    const [checked, setChecked] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleNextClick = () => {
        if (otpCode === item.OTPCode.toString()) {
            const localToken = localStorage.getItem('token');
            Services.LoginByPhone(localToken, item.phone)
                .then((res) => {
                    localStorage.setItem("name",res.data.result.name)
                    localStorage.setItem("lastname",res.data.result.lastname)
                    localStorage.setItem("userToken",res.data.result.token)
                    localStorage.setItem("mallID",res.data.result.mallID)
                    localStorage.setItem("userID",res.data.result.userID)
                    if (res.data.status) {
                        navigate('/list-page');
                    }
                    else {
                        console.log("err");
                    }
                })
                .catch((err) => console.log(err));
        }
        else {
            setShowError(true)
        }
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
            <AppBar position="static" color="transparent" elevation={0} sx={{ alignItems: 'center' }}>
                <Toolbar sx={{ position: 'relative', width: '100%' }}>
                    <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        <img src={Logo} alt="Logo" style={{ height: '24.45px' }} />
                    </Box>

                    <Box sx={{ marginLeft: 'auto' }}>
                        <img src={TR} alt="TR Flag" />
                    </Box>
                </Toolbar>
            </AppBar>
            <Typography style={{ fontWeight: '700', fontSize: '16', fontFamily: 'Inter', marginTop: '49.62px' }}>
                Adrese Teslimat Servisi
            </Typography>

            <TextField
                label="Kod"
                variant="outlined"
                fullWidth
                style={{ marginTop: '2rem', backgroundColor: 'white' }}
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
            />
            {showError && <Typography sx={{marginTop:'10px'}}>Kodunuz eşleşmedi lütfen tekrar deneyiniz.</Typography>}
            <FormControlLabel
                sx={{ marginTop: '42px' }}
                control={<CustomCheckBox checked={checked} onChange={handleCheckboxChange} />}
                label={
                    <Typography sx={{ textAlign: 'left', color: '#5E596D', fontSize: '12px', fontWeight: '400', fontFamily: 'Inter' }}>
                        Avm Lojistik Tic. A.Ş şirketinden almış olduğum hizmet kapsamında, 6698 sayılı kanun uyarınca, bilgilendirme sunulan Aydınlatma Metnini okudum, anladım, onaylıyorum.
                    </Typography>
                }
            />
            <CustomButton disabled={!otpCode || !checked} onClick={handleNextClick} fullWidth sx={{ marginTop: '42px' }}>
                Devam Et
            </CustomButton>
            <Typography sx={{ color: "#E92434", fontSize: '12px', fontFamily: 'Inter', fontWeight: '400', marginTop: '24px', cursor: 'pointer' }}>
                TEKRAR KOD GÖNDER
            </Typography>
        </Container>
    )
}
